import * as yup from "yup";

export interface VolunteerLog {
  id: number,
  note: string,
  type: string,
  user_id: number,
  created: string,
  modified: string,
  user?: User,
}

export interface Volunteer {
  id: number,
  user_id: number,
  event_id: number,
  photography_consent: boolean,
  rosters?: Roster[],
  created: string,
  modified: string,
}

export interface MainUser extends User {
  shared: User[]
}

export interface Event {
  id: number,
  name: string,
  year: string,
  description: string,
  job_notes: string,
  open: string,
  closed: string,
  event_start: string,
  event_end: string,
  map_url: string,
  created: string,
  modified: string,
}

export interface Job {
  id: number,
  area_id: number,
  area: Area,
  title: string,
  type: string,
  volunteers_needed: number,
  volunteers_max: number,
  priority: number,
  short_description: string,
  description: string,
  shifts: Shift[],
  created: string,
  modified: string,
}

export interface Area {
  id: number,
  title: string,
  description: string,
  jobs?:[],
  shifts?: Shift[],
  created: string,
  modified: string,
  area_leaders?: any[],
}

export interface Shift {
  id: number,
  event_id: number,
  title: string,
  description: string,
  start_time: string,
  end_time: string,
  shift_category_id: number,
  created: string,
  modified: string,
}

export interface Roster {
  id: number,
  shift_id: number,
  volunteer_id: number,
  area_id: number,
  job_id: number,
  shift: Shift,
  area: Area,
  job: Job,
  created: string,
  modified: string,
}

export interface ShiftCategory {
  id: number,
  title: string,
  created: string,
  modified: string,
}

export default interface User {
    id: number | undefined,
    firstname: string,
    surname: string,
    email_address?: string,
    group_id: number,
    image?: string,
    contact_phone_1: string,
    contact_phone_2?: string,
    gender: string | undefined,
    birth_year: number | undefined,
    sms: string,
    next_of_kin: string,
    next_of_kin_phone: string,
    next_of_kin_relationship: string,
    latest?: VolunteerLog | undefined,
  }


  export const UserSchema = yup.object().shape({
    firstname: yup.string().max(100).required('Firstname is required!'),  
    surname: yup.string().max(100).required('Surname is required!'),
    contact_phone_1: yup.string().max(20),
    sms: yup.string().max(20),
    next_of_kin: yup.string().max(100).required('Next of kin is required!'),
    birth_year: yup.number(),
    gender: yup.string().max(7).required('Gender is required!'),
    next_of_kin_phone: yup.string().max(20).required('Next of kin phone is required!'),
    next_of_kin_relationship: yup.string().max(45).required('Relationship is required!')
  });

export interface UserErrors {
  firstname: any,
  surname: any,
  email_address: any,
  //group_id: any,
  //image: any,
  contact_phone_1: any,
 // contact_phone_2: any,
  gender: any,
  birth_year: any,
  sms: any,
  next_of_kin: any,
  next_of_kin_phone: any,
  next_of_kin_relationship: any,
  }